/*------------------------------------------------------------------------------
  MaterialWrap - Angular 4 Seed Project
------------------------------------------------------------------------------*/
@use '@angular/material' as mat;
//== mixins
@import '../node_modules/compass-mixins/lib/animate';
//== Vendor
@import '../node_modules/multi-brand-colors/dist/scss/index.scss';
@import '../node_modules/mdi/css/materialdesignicons.css';
@import '../node_modules/material-design-iconic-font/dist/css/material-design-iconic-font.css';
//== App
@import "./app/shared/styles/app";
//@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";



/***************************************************
	Anna Actualització Angular 12 (23/08/2022)
********************************************************/

@import '~@angular/material/theming';

// always include only once per project
@include mat-core();

// import our custom theme
@import './app/shared/styles/themes/theme-default.scss';

// specify theme class eg: <body class="my-theme"> ... </body>
.my-theme {
  // use our theme with angular-material-theme mixin
  @include angular-material-theme($my-theme);
}