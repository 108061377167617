//--------------------------------------------
// Helpers - Classes to DRY and Scale CSS
//--------------------------------------------
//== Margins

.m-0 {
	margin: 0 !important;
}

.m-5 {
	margin: 5px !important;
}

.m-10 {
	margin: 10px !important;
}

.m-15 {
	margin: 15px !important;
}

.m-20 {
	margin: 20px !important;
}

.m-30 {
	margin: 30px !important;
}

.m-50 {
	margin: 50px !important;
}

.m-l-0 {
	margin-left: 0 !important;
}

.m-l-5 {
	margin-left: 5px !important;
}

.m-l-10 {
	margin-left: 10px !important;
}

.m-l-15 {
	margin-left: 15px !important;
}

.m-l-20 {
	margin-left: 20px !important;
}

.m-l-30 {
	margin-left: 30px !important;
}

.m-l-40 {
	margin-left: 40px !important;
}

.m-l-50 {
	margin-left: 50px !important;
}

.m-l-n-1 {
	margin-left: -1px !important;
}

.m-l-n-5 {
	margin-left: -5px !important;
}

.m-l-n-10 {
	margin-left: -10px !important;
}

.m-l-n-15 {
	margin-left: -15px !important;
}

.m-l-n-20 {
	margin-left: -20px !important;
}

.m-l-n-30 {
	margin-left: -30px !important;
}

.m-l-n-40 {
	margin-left: -40px !important;
}

.m-l-n-50 {
	margin-left: -50px !important;
}

.m-t-0 {
	margin-top: 0 !important;
}

.m-t-1 {
	margin-top: 1px !important;
}

.m-t-5 {
	margin-top: 5px !important;
}

.m-t-10 {
	margin-top: 10px !important;
}

.m-t-15 {
	margin-top: 15px !important;
}

.m-t-20 {
	margin-top: 20px !important;
}

.m-t-30 {
	margin-top: 30px !important;
}

.m-t-40 {
	margin-top: 40px !important;
}

.m-t-50 {
	margin-top: 50px !important;
}

.m-t-75 {
	margin-top: 75px !important;
}

.m-t-100 {
	margin-top: 100px !important;
}

.m-t-120 {
	margin-top: 120px !important;
}

.m-t-140 {
	margin-top: 140px !important;
}

.m-t-n-1 {
	margin-top: -1px !important;
}

.m-t-n-5 {
	margin-top: -5px !important;
}

.m-t-n-10 {
	margin-top: -10px !important;
}

.m-t-n-15 {
	margin-top: -15px !important;
}

.m-t-n-20 {
	margin-top: -20px !important;
}

.m-t-n-30 {
	margin-top: -30px !important;
}

.m-t-n-40 {
	margin-top: -40px !important;
}

.m-t-n-50 {
	margin-top: -50px !important;
}

.m-r-0 {
	margin-right: 0 !important;
}

.m-r-1 {
	margin-right: 1px !important;
}

.m-r-5 {
	margin-right: 5px !important;
}

.m-r-10 {
	margin-right: 10px !important;
}

.m-r-15 {
	margin-right: 15px !important;
}

.m-r-20 {
	margin-right: 20px !important;
}

.m-r-30 {
	margin-right: 30px !important;
}

.m-r-40 {
	margin-right: 40px !important;
}

.m-r-50 {
	margin-right: 50px !important;
}

.m-r-n-1 {
	margin-right: -1px !important;
}

.m-r-n-5 {
	margin-right: -5px !important;
}

.m-r-n-10 {
	margin-right: -10px !important;
}

.m-r-n-15 {
	margin-right: -15px !important;
}

.m-r-n-20 {
	margin-right: -20px !important;
}

.m-r-n-30 {
	margin-right: -30px !important;
}

.m-r-n-40 {
	margin-right: -40px !important;
}

.m-r-n-50 {
	margin-right: -50px !important;
}

.m-b-0 {
	margin-bottom: 0 !important;
}

.m-b-1 {
	margin-bottom: 1px !important;
}

.m-b-5 {
	margin-bottom: 5px !important;
}

.m-b-10 {
	margin-bottom: 10px !important;
}

.m-b-15 {
	margin-bottom: 15px !important;
}

.m-b-20 {
	margin-bottom: 20px !important;
}

.m-b-30 {
	margin-bottom: 30px !important;
}

.m-b-40 {
	margin-bottom: 40px !important;
}

.m-b-50 {
	margin-bottom: 50px !important;
}

.m-b-n-1 {
	margin-bottom: -1px !important;
}

.m-b-n-5 {
	margin-bottom: -5px !important;
}

.m-b-n-10 {
	margin-bottom: -10px !important;
}

.m-b-n-15 {
	margin-bottom: -15px !important;
}

.m-b-n-20 {
	margin-bottom: -20px !important;
}

.m-b-n-30 {
	margin-bottom: -30px !important;
}

.m-b-n-40 {
	margin-bottom: -40px !important;
}

.m-b-n-50 {
	margin-bottom: -50px !important;
}
//== Padding

.p-0 {
	padding: 0 !important;
}

.p-5 {
	padding: 5px !important;
}

.p-10 {
	padding: 10px !important;
}

.p-15 {
	padding: 15px !important;
}

.p-20 {
	padding: 20px !important;
}

.p-25 {
	padding: 25px !important;
}

.p-30 {
	padding: 30px !important;
}

.p-40 {
	padding: 40px !important;
}

.p-50 {
	padding: 50px !important;
}

.p-75 {
	padding: 75px !important;
}

.p-100 {
	padding: 100px !important;
}

.p-l-0 {
	padding-left: 0 !important;
}

.p-l-5 {
	padding-left: 5px !important;
}

.p-l-10 {
	padding-left: 10px !important;
}

.p-l-15 {
	padding-left: 15px !important;
}

.p-l-20 {
	padding-left: 20px !important;
}

.p-l-25 {
	padding-left: 25px !important;
}

.p-l-30 {
	padding-left: 30px !important;
}

.p-l-40 {
	padding-left: 40px !important;
}

.p-l-50 {
	padding-left: 50px !important;
}

.p-l-75 {
	padding-left: 75px !important;
}

.p-l-100 {
	padding-left: 100px !important;
}

.p-b-0 {
	padding-bottom: 0 !important;
}

.p-b-5 {
	padding-bottom: 5px !important;
}

.p-b-10 {
	padding-bottom: 10px !important;
}

.p-b-15 {
	padding-bottom: 15px !important;
}

.p-b-20 {
	padding-bottom: 20px !important;
}

.p-b-25 {
	padding-bottom: 25px !important;
}

.p-b-30 {
	padding-bottom: 30px !important;
}

.p-b-40 {
	padding-bottom: 40px !important;
}

.p-b-50 {
	padding-bottom: 50px !important;
}

.p-b-60 {
	padding-bottom: 60px !important;
}

.p-b-70 {
	padding-bottom: 70px !important;
}

.p-b-80 {
	padding-bottom: 80px !important;
}

.p-b-90 {
	padding-bottom: 90px !important;
}

.p-b-100 {
	padding-bottom: 100px !important;
}

.p-t-0 {
	padding-top: 0 !important;
}

.p-t-5 {
	padding-top: 5px !important;
}

.p-t-10 {
	padding-top: 10px !important;
}

.p-t-15 {
	padding-top: 15px !important;
}

.p-t-20 {
	padding-top: 20px !important;
}

.p-t-25 {
	padding-top: 25px !important;
}

.p-t-30 {
	padding-top: 30px !important;
}

.p-t-40 {
	padding-top: 40px !important;
}

.p-t-50 {
	padding-top: 50px !important;
}

.p-t-75 {
	padding-top: 75px !important;
}

.p-t-100 {
	padding-top: 100px !important;
}

.p-t-120 {
	padding-top: 120px !important;
}

.p-t-130 {
	padding-top: 130px !important;
}

.p-t-140 {
	padding-top: 140px !important;
}

.p-t-150 {
	padding-top: 150px !important;
}

.block {
	display: block !important;
}
//== Image Sizes

.img-xs {
	width: 30px;
	height: 30px;
}

.img-sm {
	width: 35px;
	height: 35px;
}

.img-md {
	width: 55px;
	height: 55px;
}

.img-lg {
	width: 100px;
	height: 100px;
}

.max-w-20 {
	max-width: 20px !important;
}

.max-w-30 {
	max-width: 30px !important;
}

.max-w-32 {
	max-width: 35px !important;
}

.max-w-35 {
	max-width: 35px !important;
}

.max-w-40 {
	max-width: 40px !important;
}

.max-w-50 {
	max-width: 50px !important;
}

.max-w-75 {
	max-width: 75px !important;
}

.max-w-100 {
	max-width: 100px !important;
}

.max-w-150 {
	max-width: 150px !important;
}

.max-w-200 {
	max-width: 200px !important;
}
.max-w-300 {
	max-width: 300px !important;
}
.max-w-400 {
	max-width: 400px !important;
}
//== Overflow

.overflow-hidden {
	overflow: hidden;
}

.overflow-auto {
	overflow: auto;
}
//== Min/Max width/height
.max-h-50 {
	max-height: 50px !important;
}
.max-h-100 {
	max-height: 100px !important;
}

.max-h-200 {
	max-height: 200px !important;
}

.max-h-250 {
	max-height: 250px !important;
}

.max-h-300 {
	max-height: 300px !important;
}

.max-h-350 {
	max-height: 300px !important;
}

.max-h-375 {
	max-height: 300px !important;
}

.max-h-400 {
	max-height: 400px !important;
}

.max-h-450 {
	max-height: 450px !important;
}

.max-h-500 {
	max-height: 500px !important;
}

.max-h-600 {
	max-height: 600px !important;
}

.max-h-650 {
	max-height: 650px !important;
}

.max-h-700 {
	max-height: 700px !important;
}

.max-h-800 {
	max-height: 800px !important;
}

.max-h-900 {
	max-height: 900px !important;
}

.max-h-1000 {
	max-height: 1000px !important;
}

.min-h-0 {
	min-height: 0 !important;
}

.min-h-100 {
	min-height: 100px !important;
}

.min-h-200 {
	min-height: 200px !important;
}

.min-h-250 {
	min-height: 250px !important;
}

.min-h-300 {
	min-height: 300px !important;
}

.min-h-350 {
	min-height: 300px !important;
}

.min-h-375 {
	min-height: 300px !important;
}

.min-h-400 {
	min-height: 400px !important;
}

.min-h-450 {
	min-height: 450px !important;
}

.min-h-500 {
	min-height: 500px !important;
}

.min-h-600 {
	min-height: 600px !important;
}

.min-h-650 {
	min-height: 650px !important;
}

.min-h-700 {
	min-height: 700px !important;
}

.min-h-800 {
	min-height: 800px !important;
}

.min-h-900 {
	min-height: 900px !important;
}

.min-h-1000 {
	min-height: 1000px !important;
}
//== width/height

.h-5 {
	height: 5px !important;
}

.h-10 {
	height: 10px !important;
}

.h-50 {
	height: 50px !important;
}

.h-75 {
	height: 75px !important;
}

.h-100 {
	height: 100px !important;
}

.h-125 {
	height: 125px !important;
}

.h-150 {
	height: 150px !important;
}

.h-200 {
	height: 200px !important;
}

.h-300 {
	height: 300px !important;
}

.h-400 {
	height: 400px !important;
}

.h-450 {
	height: 450px !important;
}

.h-500 {
	height: 500px !important;
}

.h-600 {
	height: 600px !important;
}

.h-650 {
	height: 650px !important;
}

.h-700 {
	height: 700px !important;
}

.h-800 {
	height: 800px !important;
}

.h-900 {
	height: 900px !important;
}

.h-1000 {
	height: 1000px !important;
}

.w-5 {
	width: 5px !important;
}

.w-10 {
	width: 10px !important;
}

.w-15 {
	width: 15px !important;
}

.w-20 {
	width: 20px !important;
}
.w-50 {
	width: 20px !important;
}
.w-100 {
	width: 100px !important;
}
.w-150 {
	width: 150px !important;
}
.w-200 {
	width: 200px !important;
}
.w-250 {
	width: 250px !important;
}
.w-300 {
	width: 300px !important;
}
.w-100p {
	width: 100% !important;
}

.h-100p {
	width: 100% !important;
}
//= positions

.absolute {
	position: absolute !important;
}

.relative {
	position: relative !important;
}

.block {
	display: block !important;
}

.inline-block {
	display: inline-block !important;
}

.list-block li {
	display: block !important;
}

.list-inline li {
	display: inline !important;
}

.clear-both {
	clear: both !important;
}
.line-height-normal {
	line-height: normal !important;
}
.line-height-8 {
	line-height: 8px !important;
}
.line-height-12 {
	line-height: normal !important;
}
ul.style-none {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		font-size: rem(14px);
		padding: 3px 0;
		ul {
			list-style: none;
		}
	}
}

ul.vertical-icon-list {
	margin: 0;
	padding: 0;
	list-style: none;

	li {
		font-size: rem(14px);
		padding: 6px 0;

		i {
			font-size: rem(20px);
			padding-right: 15px;
			vertical-align: middle;
		}
	}
}
//== Solid App Backgrund Colors

.bg-green {
	background: $mw_green !important;
}

.bg-blue {
	background: blue !important;
}

.bg-lightBlue {
	background: $mw_lightBlue !important;
}

.bg-red {
	background: $mw_red !important;
}

.bg-yellow {
	background: $mw_yellow !important;
}

.bg-paleYellow {
	background: $mw_paleYellow !important;
}

.bg-lightGray {
	background: $mw_lightGray !important;
}

.bg-gray {
	background: $mw_gray !important;
}

.bg-drakGray {
	background: $mw_drakGray !important;
}

.bg-grayBlue {
	background: $mw_grayBlue !important;
}

.bg-purple {
	background: $mw_purple !important;
}

.bg-turquoise {
	background: $mw_turquoise !important;
}

.bg-peach {
	background: $mw_peach !important;
}

.bg-transparent {
	background: transparent !important;
}
//== Text Colors

.text-green {
	color: $mw_green !important;
}

.text-turquoise {
	color: $mw_turquoise !important;
}

.text-red {
	color: $mw_red !important;
}

.text-cyan {
	color: $mw_cyan !important;
}

.text-yellow {
	color: $mw_yellow !important;
}

.text-gray {
	color: $mw_gray !important;
}

.text-blue {
	color: blue !important;
}

.text-orange {
	color: #ffb74d !important;
}

.text-purple {
	color: $mw_purple !important;
}

.text-white {
	color: #fff !important;
}

.text-brown {
	color: #795548 !important;
}

.text-salmon {
	color: $mw_salmon !important;
}

.text-muted {
	color: rgb(131, 150, 176);
}

.color-black {
	color: #000 !important;
}

.color-white {
	color: #fff !important;
}

.bg-white {
	background-color: #fff !important;
}

.transparent {
	background: transparent !important;
}
//Borders

.border-bottom,
.border-grey-bottom-1px {
	border-bottom: 1px solid rgba(#d7e1ed, .8) !important;
}

.border-grey-top-1px,
.border-top {
	border-top: 1px solid rgba(#d7e1ed, .6) !important;
}
//shadows

.shadow-none {
	box-shadow: none !important;
}

.shadow-z-1 {
	@include shadow-z-1();
}

.shadow-z-1:hover {
	@include shadow-z-1-hover();
}

.shadow-z-2 {
	@include shadow-z-2();
}

.shadow-z-3 {
	@include shadow-z-3();
}

.shadow-z-4 {
	@include shadow-z-4();
}

.shadow-z-5 {
	@include shadow-z-5();
}

.big-shadow {
	@include big-shadow();
}

.shadow-2dp {
	@include shadow-2dp();
}

.shadow-3dp {
	@include shadow-3dp();
}

.shadow-4dp {
	@include shadow-4dp();
}

.shadow-6dp {
	@include shadow-6dp();
}

.shadow-8dp {
	@include shadow-8dp();
}

.shadow-16dp {
	
}

.shadow-24dp {
	@include shadow-24dp();
}

.font-weight-100 {
	font-weight: 100 !important;
}

.font-weight-200 {
	font-weight: 200 !important;
}

.font-weight-300 {
	font-weight: 300 !important;
}

.font-weight-400 {
	font-weight: 400 !important;
}

.font-weight-500 {
	font-weight: 500 !important;
}

.font-weight-600 {
	font-weight: 600 !important;
}

.font-weight-700 {
	font-weight: 700 !important;
}

.font-weight-800 {
	font-weight: 800 !important;
}

.font-weight-900 {
	font-weight: 900 !important;
}

.font-weight-light {
	font-weight: 100 !important;
}

.font-weight-normal {
	font-weight: 300 !important;
}

.font-weight-medium {
	font-weight: 400 !important;
}

.font-weight-bold {
	font-weight: 500 !important;
}

.font-size-0 {
	font-size: 0 !important;
}

.font-size-10 {
	font-size: 10px !important;
}
.font-size-11 {
	font-size: 11px !important;
}

.font-size-12 {
	font-size: 12px !important;
}

.font-size-14 {
	font-size: 14px !important;
}

.font-size-16 {
	font-size: 16px !important;
}

.font-size-18 {
	font-size: 18px !important;
}

.font-size-20 {
	font-size: 20px !important;
}

.font-size-24 {
	font-size: 24px !important;
}

.font-size-26 {
	font-size: 26px !important;
}

.font-size-30 {
	font-size: 30px !important;
}

.font-size-40 {
	font-size: 40px !important;
}

.font-size-50 {
	font-size: 50px !important;
}

.font-size-60 {
	font-size: 60px !important;
}

.font-size-70 {
	font-size: 70px !important;
}

.font-size-80 {
	font-size: 80px !important;
}

.hidden {
	display: none !important;
}

@media (max-width: 576px) {
	.d-xs-none {
		display: none !important;
	}
	.d-xs-inline {
		display: inline !important;
	}
	.d-xs-inline-block {
		display: inline-block !important;
	}
	.d-xs-block {
		display: block !important;
	}
	.d-xs-table {
		display: table !important;
	}
	.d-xs-table-cell {
		display: table-cell !important;
	}
	.d-xs-flex {
		display: -ms-flexbox !important;
		display: flex !important;
	}
	.d-xs-inline-flex {
		display: -ms-inline-flexbox !important;
		display: inline-flex !important;
	}
}
