.mw-green {
	background: $brand-success !important;
	color: #fff !important;
}

.mw-blue {
	background: $mw-blue !important;
	color: #fff !important;
}
.mw-blue-text {
	color: $mw-blue !important;
}
.mw-royalBlue {
	background: #4285f4 !important;
}
.mw-royalBlue-text {
	color: #4285f4 !important;
}
.mw-darkBlue {
	background: #222f3c;
}
.mw-lightBlue {
	background: #2ebaee !important;
	color: #fff !important;
}

.mw-red {
	background: #fb4869 !important;
	color: #fff !important;
}

.mw-yellow {
	background: $mat-yellow-500 !important;
	color: #3e3e3e !important;
}

.mw-paleYellow {
	background: #fddd7e !important;
	color: #fff !important;
}

.mw-lightGray {
	background: #eef5f9 !important;
	color: #6f829c !important;
}

.mw-gray {
	background: #8f9eb5 !important;
	color: #fff !important;
}

.mw-darkGray {
	background: #707c94 !important;
	color: #fff !important;
}

.mw-grayBlue {
	background: #59779b !important;
	color: fff !important;
}

.mw-purple {
	background: #6b79c4 !important;
	color: #fff !important;
}

.mw-turquoise {
	background: #00c5dc !important;
	color: #fff !important;
}

.mw-peach {
	background: #feb38d !important;
	color: #fff !important;
}

.mw-salmon {
	background: #ee6e73 !important;
	color: #fff !important;
}
.mw-salmon-text {
	color: #ee6e73 !important;
}
.mw-white {
	background: #fff !important;
}
.highlight {
	background-color: #e8f0fe !important;
}
.highlight2 {
	background-color: #fffde7 !important;
}
