.mat-sidenav-content,
.mat-drawer-container,
.mat-sidenav-content,
.mat-drawer-content ,{
	background-color: #EEF5F9!important;
	overflow: initial!important;
}
.mat-input-underline,
.mat-select-underline {
	background-color: rgba(0, 0, 0, 0.12) !important;
}
.mat-form-field {
	width: auto !important;
	display: block !important;
}
